@import "../theme/mixins";

$brand: 'olearys';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/Linotype\ -\ FuturaLTPro-Book.otf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/Linotype\ -\ FuturaLTPro-Medium.otf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/Linotype\ -\ FuturaLTPro-Bold.otf");
}
@font-face {
  font-family: AppFontBoldCursive;
  src: url("../assets/fonts/Linotype\ -\ FuturaLTPro-BoldOblique.otf");

}
@font-face {
  font-family: Title;
  src: url("../assets/fonts/Jeff\ Levine\ -\ VehicleJNL.otf");
}
@font-face {
  font-family: SecondaryFont;
  src: url("../assets/fonts/Cabin-Regular.otf");
}
@font-face {
  font-family: SecondaryFontBold;
  src: url("../assets/fonts/Cabin-Bold.otf");
}

:root {
  --brand: $brand;
  --ion-color-primary: #006958;
	--ion-color-primary-rgb: 0,105,88;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #005c4d;
	--ion-color-primary-tint: #1a7869;

	--ion-color-secondary: #FFDC00;
	--ion-color-secondary-rgb: 255,220,0;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #e0c200;
	--ion-color-secondary-tint: #ffe01a;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium: #393939;
	--ion-color-medium-rgb: 57,57,57;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #323232;
	--ion-color-medium-tint: #4d4d4d;


  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
  --ion-color-title: #36A493;
}

h1 {
  font-family: AppFontBold, Helvetica, sans-serif;
}

.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  background: var(--ion-color-primary) url(#{$brandAssets}/menu-modal.svg) no-repeat right bottom / 50% !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;
  --background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal,
app-payment-success {
  background: white url(../assets/olearys/background_payment.png) no-repeat center center / 100% 100% !important;
  --background: white url(../assets/olearys/background_payment.png) no-repeat center center / 100% 100% !important;
  @include desktop {
    // transform: rotate(180deg);
    background: white url(../assets/olearys/background_payment_desktop.png) no-repeat center center / 100% 100% !important;
  --background: white url(../assets/olearys/background_payment_desktop.png) no-repeat center center / 100% 100% !important;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  background:  var(--ion-color-primary) url(#{$brandAssets}/home_page_desktop.png) no-repeat center top / 100% 100% !important;
  background-size: cover !important;

  @include mobile {
    background: var(--ion-color-primary) url(#{$brandAssets}/home_background_mobile.png) no-repeat center top / 100% 100% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  ion-footer {
    background: transparent !important;
    --background: transparent !important;
  }
}

